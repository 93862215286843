import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Iconify from '#/components/shared/ui/Iconify';
import { RHFTextField, FormProvider } from '../../shared/hook-form';
import { useCompleteResetPassword } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';
import { setSession } from '#/utils/jwt';
import useAuth from '#/hooks/useAuth';

type FormValuesProps = {
  password: string;
  confirmPassword: string;
};

type Props = {
  uidb64: string;
  token: string;
};
export default function AuthNewPasswordForm({ uidb64, token }: Props) {
  const { refetch } = useAuth();
  const { translate } = useLocales();
  const fromPartner = localStorage.getItem('gg_partner_key');

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const { mutateAsync: completePasswordReset, data } =
    useCompleteResetPassword(fromPartner);

  const VerifyCodeSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'validations.user.minPassword')
      .required('validations.user.password'),
    confirmPassword: Yup.string()
      .required('validations.user.confirmPassword')
      .oneOf([Yup.ref('password')], 'validations.user.passwordMatch'),
  });

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const request = {
      uidb64,
      token,
      password: data.password,
    };

    try {
      await completePasswordReset(request).then(() => {
        toast.success(
          String(translate('toast_notifications.success.password_update'))
        );
      });
    } catch (error) {
      toast.error(
        String(translate('toast_notifications.error.password_update'))
      );
    }
  };

  useEffect(() => {
    if (data?.data?.success) {
      if (data?.data?.token) {
        setSession(data?.data?.token);
        refetch();
      } else navigate('/auth/login');
    }
    // eslint-disable-next-line
  }, [data?.data?.success]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          autoComplete="new-password"
          label={String(translate('global.formLabels.newPassword'))}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          autoComplete="confirm-password"
          label={String(translate('global.formLabels.confirmPassword'))}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 3 }}
        >
          <Typography>
            {String(translate('global.loginInfo.updatePassword'))}
          </Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
